import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { defaultTheme } from '../theme/default';

const GlobalStyle = createGlobalStyle`
  body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    text-rendering: optimizelegibility;
    font-family: sans-serif;
    background-color: #b8b9a4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
export default function Layout({ children }) {
    return (
        <React.Fragment>
            <GlobalStyle theme={defaultTheme} />
            {children}
        </React.Fragment>
    );
}
